.sectionContainer {
    scroll-snap-type: y mandatory;
    position: relative;
}

.section {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
    z-index: 1;
    padding: 77px 0;
}