.decorator {
    font-family: "MedievalSharp", system-ui, sans-serif;
    position: absolute;
    font-weight: bold;
    color: #8F0100;
    width: 500px;
    height: auto;
    overflow: hidden;
    user-select: none;
    z-index: 0;
}

.decoratorLine {
    overflow: hidden;
}