.appBar {
    background: linear-gradient(90deg, #f4e8b8 0%, #fffae3 35%, #f4e8b8 100%);
}

.toolBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title, .tabs, .burgerMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.tab {
    font-size: large;
    position: relative;
}

.blogTab {
    font-size: large;
    background-color: #0C4DA7;
    color: #f4e8b8;
    padding: 3px 17px;
    border-radius: 4px;
    position: relative;
    display: flex;
    align-items: center;
}

.blogTab:hover {
    background-color: #f4e8b8;
    padding: 1px 15px;
    color: #0C4DA7;
    border: 2px solid #0C4DA7;
}

.mobileMenuItem .blogTab {
    max-width: 100px;
    color: white;
    margin: 0 auto;
}

.mobileMenuItem .blogTab:hover {
    color: #0C4DA7;
    background-color: white;
}

.tab:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 13px;
    width: 0;
    border-bottom: 17px solid #0C4DA733;
    transition: 0.4s;
}

.tab:hover:after {
    width: 100%;
}

.activeTab:after {
    width: 100%;
}

.mobileMenu {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobileMenuItem {
    text-align: center;
}

@media screen {
    .tab {
        margin: 0 auto;
        width: fit-content;
    }
    .tabs {
        display: none;
    }
    .burgerMenu {
        display: flex;
        justify-content: flex-end;
    }
}

@media screen and (min-width: 900px) {
    .tabs {
        display: flex;
        visibility: visible;
    }
    .burgerMenu {
        display: none;
    }
}