html, body, #root {
    width: 100%;
    height: 100%;
    font-family: "Fira Code", system-ui, sans-serif;
}

#root {
    overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f4e8b8;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #EEC30E;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #EEC30E;
}
