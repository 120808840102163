@keyframes bounceAlpha {
    0% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
    25% {
        opacity: 0;
        transform: translateY(10px) scale(0.9);
    }
    26% {
        opacity: 0;
        transform: translateY(-10px) scale(0.9);
    }
    55% {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}

.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 33px;
    left: 0;
    top: calc(100vh - 41px);
}

.arrowButton {
    width: 33px;
    height: 33px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #0C4DA777;
    border-radius: 50%;
}

.arrowButtonIcon {
    font-size: 25px;
}