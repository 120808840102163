.form {
    display: flex;
    align-items: center;
}

.mailingListForm {
    flex-direction: row;
    justify-content: center;
}

.contactForm {
    flex-direction: row;
    justify-content: space-around;
}

.honeypot {
    visibility: hidden;
    width: 0;
    height: 13px;
}

.field {
    width: 100%;
    margin-bottom: 13px;
}

.submit {
    margin-bottom: 13px;
}

.contactFormField {
    margin-bottom: 33px;
    width: 100%;
}

.textarea {
    min-height: 50vh;
}

.contactFormSubmit {
    align-self: flex-end;
}

@media screen {
    .fieldContainer {
        padding: 0 13px;
        max-width: 1000px;
        display: flex;
        justify-content: center;
    }
    .submitContainer {
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width: 900px) {
    .fieldContainer {
    }
    .submitContainer {
        justify-content: flex-end;
    }
}