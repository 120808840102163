.description {
    margin: 33px 0;
    text-align: center;
    padding: 0 13px;
}

.partnerTitle {
    font-variant: small-caps;
}

.partnerImage {
    height: 200px;
    width: fit-content;
    align-self: center;
    opacity: 0.5;
    transition: opacity ease 0.5s 0s;
}

.partnerImage:hover {
    opacity: 0.8;
}

.partnerDescription {
    text-align: center;
}