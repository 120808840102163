.container {
    width: 80vw;
}

.containerItem {
    width: 30%;
    height: 450px;
    margin-bottom: 33px;
}

.emptyContainerItem {
    width: 30%;
}

.cardContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.imageContainer {
    position: relative;
    flex-grow: 1;
    height: 30%;
}

.image {
    height: 100%;
}

.imageCredits {
    position: absolute;
    bottom: 3px;
    left: 3px;
    font-size: 7px;
    color: white;
}

.imageCreditsLink {
    color: white;
    text-decoration-color: rgba(255, 255, 255, 0.4)
}

.ruleIndex {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 9rem;
    font-weight: bolder;
    color: white;
    line-height: 0.6;
    padding: 0;
    margin: 0;
}

.pageTitle {
    height: 100px;
}